<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        <div>
          {{ $t("organizations.title") }}
        </div>
      </slot>
    </va-card-title>

    <!-- Table -->
    <va-card-content>
      <va-data-table
        :no-data-html="$t('organizations.noResults')"
        :items="state.organizations"
      >
        <template #header(organization)>{{ $t("organizations.name") }}</template>
        <template #header(id)>{{ $t("organizations.id") }}</template>
        <template #header(createdAt)>
          {{ $t("organizations.createdAt") }}
        </template>

        <template #cell(organization)="{ source: org }">
          <router-link :to="{ path: organizationLink(org.id) }">
            {{ org.name }}
          </router-link>
        </template>

        <template #cell(id)="{ source: id }">
          <router-link :to="{ path: organizationLink(id) }">
            {{ id }}
          </router-link>
          <button-clipboard-copy
            :field="$t('organizations.id')"
            :value="id"
          />
        </template>

        <template #cell(createdAt)="{ source: createdAt }">
          <td>
            {{ printDate(createdAt) }}
          </td>
        </template>
      </va-data-table>
    </va-card-content>
    <va-card-content>
      <va-pagination
        class="pagination"
        input
        color="#000000"
        :hide-on-single-page="true"
        v-model="state.currentPage"
        :pages="state.totalPages"
        :page-size="state.perPage"
      />
    </va-card-content>
  </va-card>
</template>

<script setup>
import { reactive, watch, onMounted, inject } from "vue";
import { callFetchOrganizations } from "@/api/identity";
import ButtonClipboardCopy from "@/components/ButtonClipboardCopy.vue";
import { organizationLink } from "@/utils/identity";
import { printDate } from "@/utils";

const handleError = inject("handleError");

const state = reactive({
  organizations: [],
  loading: false,
  currentPage: 1,
  totalItems: 0,
  perPage: 10,
  totalPages: 0
});

const props = defineProps({
  params: {
    type: Object
  }
});

/**
 * Create the parameters object for the request and call it.
 * Then calculate the page properties's values.
 * @param {number} page - positive integer
 */
async function loadOrganizations(page = 1) {
  state.loading = true;

  const params = buildUrlParams(page);

  try {
    const resp = await callFetchOrganizations({ params });

    state.organizations = resp.data.items;
    state.organizations = resp.data.items.map(item => {
      return {
        organization: {
          name: item.name,
          id: item.id,
        },
        id: item.id,
        createdAt: item.createdAt,
      };
    });

    state.totalItems = resp.data.total;
    state.currentPage = page;
    state.totalPages = Math.floor(resp.data.total / state.perPage)
      + (resp.data.total % state.perPage === 0 ? 0 : 1);
  } catch (e) {
    handleError(e);
  } finally {
    state.loading = false;
  }
}

function buildUrlParams(page = 1) {
  const params = new URLSearchParams();

  Object.keys(props.params).forEach(key => {
    if (props.params[key] !== undefined) {
      if (Array.isArray(props.params[key])) {
        props.params[key].forEach(p => params.append(key, p));
      } else {
        params.append(key, props.params[key]);
      }
    }
  });

  params.set("count", state.perPage);
  params.set("page", page);

  return params;
}



watch(
  () => state.currentPage,
  async currentPage => {
    await loadOrganizations(currentPage);
  }
);

watch(
  () => props.update,
  async () => {
    await loadOrganizations();
  }
);

onMounted(async () => {
  await loadOrganizations();
});
</script>
